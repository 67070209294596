@import 'variables';

.mat-progress-bar-error {
  .mat-progress-bar-fill::after {
    background-color: map-get($colors, 'red-error');
  }
}

.mat-progress-bar-warn {
  .mat-progress-bar-fill::after {
    background-color: map-get($colors, 'yellow-warn');
  }
}

.mat-progress-bar-success {
  .mat-progress-bar-fill::after {
    background-color: map-get($colors, 'green-success');
  }
}

.mat-progress-bar-slow-5s {
  &.mat-progress-bar {
    transition: opacity 5000ms linear;

    .mat-progress-bar-buffer.mat-progress-bar-element {
      transition: transform 5000ms ease;
    }

    .mat-progress-bar-fill.mat-progress-bar-element {
      transition: transform 5000ms ease;
    }
  }
}
