@import 'variables';

.main-footer {
  display: flex;
  height: 5%;
  background-color: map-get($colors, 'workspace-bg-gray');

  .copyright {
    color: map-get($colors, 'default-black');
    margin: auto;
    text-align: center;
    font-size: 12px;
  }
}
