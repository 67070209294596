@import 'variables';

.mat-snack-bar-container {
  color: map-get($colors, 'default-white');

  &.snack-info {
    background-color: map-get($colors, 'blue-info');
  }

  &.snack-success {
    background-color: map-get($colors, 'green-success');
  }

  &.snack-warn {
    background-color: map-get($colors, 'yellow-warn');
  }

  &.snack-error {
    background-color: map-get($colors, 'red-error');
  }

  .mat-simple-snackbar-action {
    color: inherit;
  }
}
