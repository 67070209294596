@import 'variables';

.table-item-highlight.mat-table {
  .mat-row:hover:not(.no-action) {
    background-color: map-get($colors, 'default-pink-light');
    cursor: pointer;
  }
}

.table-container {
  max-height: 100%;
  overflow: auto;
  display: block;
}

.table-inner-row {
  margin-left: 5px;
  margin-bottom: 5px;
}

tr {
  th.mat-column-index,
  td.mat-column-index {
    border-right: 0.5px solid map-get($colors, 'default-gray-light');
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    vertical-align: middle;
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding-left: 10px;
  }
}
.mat-column-action {
  width: 88px;
}

table {
  width: 100%;
}
