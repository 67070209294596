@import 'variables';

.icon-copy-link {
  font-size: 16px;
  text-align: center;
  top: 3px;
  position: relative;
  cursor: pointer;
}

.icon-hover {
  &:hover {
    background-color: map-get($colors, 'default-gray-light');
  }
}

.bad-access {
  color: map-get($colors, 'default-gray-light');

  &-text {
    text-align: center;
    margin: auto !important;
    font-size: 20px;
  }

  &-icon {
    width: 150px !important;
    height: 150px !important;
    font-size: 150px;
    vertical-align: middle;
    margin: auto;
  }
}

.repproval-info {
  margin-right: 8px;
  font-size: 16px;
  text-align: center;
  top: 3px;
  position: relative;
}

.message-icon {
  margin-right: 12px;
  margin-bottom: 12px;
}

.close-nav-bar {
  margin-bottom: 12px;
}

.process-error-icon {
  margin-bottom: 12px;
  margin-top: -12px;
}

.show-detail-icon {
  font-size: 16px;
  background-color: transparent;
}

.activate-icon {
  font-size: 18px;
  background-color: transparent;
  color: map-get($colors, 'green-success');
}

.deactivate-icon {
  font-size: 18px;
  background-color: transparent;
  color: map-get($colors, 'red-error');
}
