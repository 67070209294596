@import 'variables';
@import 'components';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: map-get($colors, 'gray-background');
}

.height-100vh {
  min-height: 100vh;
}

.vertical-align-center {
  align-items: flex-start;
}

.horizontal-align-center {
  justify-content: center;
}

.justify-align-center {
  justify-content: center;
  align-items: center;
}

.display-block-align-middle {
  display: inline-block;
  vertical-align: middle;
}

.single-col-w {
  width: 550px;
}
.double-col-w {
  width: 800px;
}

.text-align-right {
  text-align: right;
}

.padding-50px {
  padding: 50px;
}

.debug > *,
.debug {
  outline: 2px solid map-get($colors, 'red');
}

.justify-content-around {
  justify-content: space-between;
  display: flex;
}

.d-flex-align-center {
  display: flex;
  height: 100vh;
  align-items: center;
}

.d-flex-align-end {
  display: flex;
  justify-content: right;
}

.vertical-auto-margin {
  margin-top: auto;
  margin-bottom: auto;
}

.w-10 {
  width: 10%;
}

.flex-1 {
  flex: 1;
}

@keyframes shine {
  to {
    background-position: 200% 0;
  }
}

.row {
  margin-left: 0;

  &.row-align {
    --bs-gutter-x: 0;
  }
}

.text-end {
  text-align: end;
}

tr.expanded-row {
  &.show {
    display: contents;
  }

  &:not(.show) {
    display: none;
  }
}

.expanded-row {
  display: flex;
}

.with-new-line {
  white-space: pre-wrap;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  height: 100%;
  width: 100%;
  background-color: map-get($colors, 'default-black');
  opacity: 50%;
}

.spacer {
  flex: 1 1 auto;
}
