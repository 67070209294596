@import 'variables';

.list-filter-form-field {
  width: 100%;
  min-width: 28px;
  display: block;
  height: min-content;
  font-size: map-get($font-size, 'font-size-medium');
}

.dialog-form-field {
  width: 100%;
  min-width: 28px;
  display: block;
  height: min-content;
  font-size: map-get($font-size, 'font-size-medium');
}

.container-form-field {
  width: 100%;
  min-width: 28px;
  font-size: map-get($font-size, 'font-size-medium');
}
