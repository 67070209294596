@import 'variables';

.mat-step-header .mat-step-icon-state-done {
  background-color: transparent !important;
  color: map-get($colors, 'green-success');
  + .mat-step-label {
    color: map-get($colors, 'default-black') !important;
  }
}

.mat-step-header .mat-step-icon-state-edit {
  background-color: transparent !important;
  color: map-get($colors, 'blue-info') !important;
  + .mat-step-label {
    color: map-get($colors, 'default-black') !important;
  }
}

.mat-step-header .mat-step-icon-state-future {
  background-color: transparent !important;
  color: map-get($colors, 'default-gray') !important;
  + .mat-step-label {
    color: map-get($colors, 'default-black') !important;
  }
}

.mat-step-header .mat-step-icon-state-interruption {
  background-color: transparent !important;
  color: map-get($colors, 'red') !important;
  + .mat-step-label {
    color: map-get($colors, 'default-black') !important;
  }
}

.mat-step-header {
  pointer-events: none !important;
}

.mat-stepper-vertical .mat-vertical-stepper-header {
  padding: 0px;
  height: auto;
}
.mat-stepper-vertical .mat-stepper-vertical-line {
  display: none;
}

.status-tracker {
  margin-top: -10px;
}

.mat-horizontal-content-container {
  text-align: center;
  overflow: hidden;
  padding: 0;
}
