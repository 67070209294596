@import 'variables';

.outer-card {
  margin-top: 20px;
}

.basic-card-list-item {
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px map-get($colors, 'default-black');
  border-radius: 5px;
}

.basic-card-list-item span {
  font-size: map-get($font-size, 'font-size-medium');
  opacity: 0.5;
}

.basic-card-list-item p {
  margin-bottom: 15px !important;
}

.basic-card-list-item div {
  flex-direction: row !important;
}

.dialog-card-list-item {
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px map-get($colors, 'default-black');
  border-radius: 5px;
  margin-top: 5px;
}

.dialog-card-list-item p {
  margin-bottom: 15px !important;
}

.mat-card {
  .notification-title {
    font-size: map-get($font-size, 'font-size-large');
    margin-bottom: 0px;
  }

  .notification-subtitle {
    font-size: map-get($font-size, 'font-size-medium');
    margin-bottom: 5px;
  }

  .notification-text {
    font-size: map-get($font-size, 'font-size-medium');
    margin-bottom: 0px;
  }
}

.notification-card {
  &--read {
    opacity: 35%;
  }
  &--unread {
    opacity: 100%;
  }
  .mat-button {
    font-size: map-get($font-size, 'font-size-medium');
  }
}

.info-card {
  margin-bottom: 10px;
  font-size: map-get($font-size, 'font-size-large');
  background-color: map-get($colors, 'blue-info');
  color: map-get($colors, 'default-white');
}
