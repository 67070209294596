@import 'variables';

.breadcrumb {
  font-size: map-get($font-size, 'font-size-large');

  span {
    color: map-get($colors, 'default-gray');

    &:hover {
      color: map-get($colors, 'default-black');
      text-decoration: underline;
    }
  }

  li {
    list-style: none;
    float: left;
    margin: 5px;

    &:after {
      padding-left: 10px;
      content: '/\00a0';
      color: map-get($colors, 'default-gray');
    }

    &:last-child {
      & span {
        margin-right: 20px;
        color: map-get($colors, 'default-black');
      }

      &:after {
        content: '';
      }
    }
  }
}
