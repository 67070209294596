@import 'variables';

.app-raised-button {
  .mat-raised-button {
    width: 100%;
  }
  &:hover {
    background-color: darken(map-get($colors, 'default-indigo-pink'), 10%);
  }

  &-green {
    background-color: map-get($colors, 'default-indigo-green') !important;
    &:hover {
      background-color: darken(map-get($colors, 'default-indigo-green'), 5%) !important;
    }
  }

  &-icon {
    max-width: 45px;
  }

  font-size: map-get($font-size, 'font-size-medium');
  max-height: 45px;
  min-height: 35px;
}

.app-basic-raised-button {
  .mat-raised-button {
    width: 100%;
  }
  &:hover {
    background-color: darken(map-get($colors, 'default-gray-light'), 10%);
  }

  &-green {
    background-color: map-get($colors, 'default-indigo-green') !important;
    &:hover {
      background-color: darken(map-get($colors, 'default-indigo-green'), 5%) !important;
    }
  }

  &-icon {
    max-width: 45px;
  }

  font-size: map-get($font-size, 'font-size-medium');
  max-height: 45px;
  min-height: 35px;
}

.dialog-raised-button {
  &:hover {
    background-color: darken(map-get($colors, 'default-indigo-pink'), 10%);
  }

  &-icon {
    max-width: 45px;
  }
  width: 30%;
  font-size: map-get($font-size, 'font-size-medium');
  max-height: 45px;
  min-height: 35px;
}

.dialog-basic-raised-button {
  &:hover {
    background-color: darken(map-get($colors, 'default-gray-light'), 10%);
  }

  &-icon {
    max-width: 45px;
  }
  width: 30%;
  font-size: map-get($font-size, 'font-size-medium');
  max-height: 45px;
  min-height: 35px;
}

.close-button {
  float: right;
  top: -24px;
  right: -24px;
}

.wrapper-button {
  &-icon {
    background-color: transparent !important;
    color: map-get($colors, 'default-black') !important;
    .badge {
      position: absolute;
      top: 4px;
      right: 15px;
      border-radius: 50%;
      background: map-get($colors, 'red');
      color: map-get($colors, 'default-white');
      font-size: map-get($font-size, 'font-size-small');
    }
  }
}
