@import 'variables';

.container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
}

.container-vertical-align-margin-auto {
  min-width: 400px;
  min-height: 400px;
  margin: auto;
  vertical-align: middle;
}

.main-container {
  height: 95%;
}

.workspace-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container-expand-panel {
  padding: 5px;
}
