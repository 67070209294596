@import 'variables';

.basic-chip {
  display: inline-block;
  text-align: center;
  margin: 5px;
  font-size: map-get($font-size, 'font-size-medium');
  border-radius: 25px;
  color: map-get($colors, 'default-white');
  background-color: map-get($colors, 'green-success');

  &.green {
    background-color: map-get($colors, 'green-success');
    &:hover {
      background-color: map-get($colors, 'green-success-darken-1');
    }
  }

  &.red {
    background-color: map-get($colors, 'red-error');
    &:hover {
      background-color: map-get($colors, 'red-error-darken-1');
    }
  }

  &.pink {
    background-color: map-get($colors, 'default-indigo-pink');
  }

  .mat-icon-button {
    width: 30px;
    height: 20px;
    line-height: 20px;
  }

  .close-chip-icon {
    font-size: 16px;
  }
}
