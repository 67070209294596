@import 'variables';

.label-item {
  padding: 5px;

  span {
    font-size: 0.85em;
    opacity: 0.5;
  }
}

.label-error {
  color: map-get($colors, 'red-error');
}

.label-success {
  color: map-get($colors, 'green-success');
}

.label-info {
  color: map-get($colors, 'blue-info');
}

.label-warn {
  color: map-get($colors, 'yellow-warn');
}
