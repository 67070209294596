@import 'variables';
.nav-side-bar {
  width: 20%;
  min-width: 10%;
}

.notifications-sidenav {
  background-color: map-get($colors, 'default-white');
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
  max-width: 20%;
  z-index: 101;
  overflow-y: hidden;
}

.notifications-list {
  height: 90%;
  max-height: 100%;
  overflow-y: scroll;
}

.empty-notifications-list {
  text-align: center;
  margin: 15px;
  color: map-get($colors, 'default-gray');
}
