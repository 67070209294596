@import 'variables';

.skeleton {
  text-indent: -9999px;
  width: 80%;
  border-radius: 6px;
  display: inline-block;
  background: linear-gradient(
      60deg,
      map-get($colors, 'default-white'),
      map-get($colors, 'default-white'),
      map-get($colors, 'default-gray-light')
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50% 100%;
  background-position: 0 0;
  animation: shine 0.5s infinite;
}
